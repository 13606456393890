import React from 'react'

import Layout from 'components/Layout'
import Legal from 'components/Layouts/Legal'

import MarkdownContent from 'content/imprint'

const Page = ({ location: { pathname } }) => (
  <Layout seo={{ title: 'Imprint', pathname }}>
    <Legal title="Imprint" lastUpdatedAt="01.12.2020">
      <MarkdownContent />
    </Legal>
  </Layout>
)

export default Page
