import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Information in accordance with Section 5 TMG`}</h2>
    <p>{`Labor Arti UG (haftungsbeschränkt)`}<br />{`
Lohmühlenstraße 65`}<br />{`
c/o Factory Works GmbH`}<br />{`
12435 Berlin`}</p>
    <h2>{`Contact Information`}</h2>
    <p>{`Telephone: +4915171993206`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:hvoostik@gmail.com"
      }}>{`hvoostik@gmail.com`}</a></p>
    <h2>{`Register entry`}</h2>
    <p>{`Entry in: Handelsregister`}<br />{`
Register Number: HRB 209030 B`}<br />{`
Register Court: Berlin Charlottenburg`}</p>
    <h2>{`Capital`}</h2>
    <p>{`Initial or original capital: 1 €`}</p>
    <h2>{`VAT number`}</h2>
    <p>{`VAT indentification number in accorance with Section 27 a of the German VAT act DE325411093`}</p>
    <h2>{`Disclaimer`}</h2>
    <h3>{`Accountability for content`}</h3>
    <p>{`The contents of our pages have been created with the utmost care. However, we cannot guarantee the contents' accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible for our own content on these web pages. In this matter, please note that we are not obliged to monitor the transmitted or saved information of third parties, or investigate circumstances pointing to illegal activity. Our obligations to remove or block the use of information under generally applicable laws remain unaffected by this as per §§ 8 to 10 of the Telemedia Act (TMG).`}</p>
    <h3>{`Accountability for links`}</h3>
    <p>{`Responsibility for the content of external links (to web pages of third parties) lies solely with the operators of the linked pages. No violations were evident to us at the time of linking. Should any legal infringement become known to us, we will remove the respective link immediately.`}</p>
    <h3>{`Copyright`}</h3>
    <p>{`Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law, every form of utilizing, reproducing or processing works subject to copyright protection on our web pages requires the prior consent of the respective owner of the rights. Individual reproductions of a work are only allowed for private use. The materials from these pages are copyrighted and any unauthorized use may violate copyright laws.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      