import React from 'react'
import { MDXProvider } from '@mdx-js/react'

const isExternalUrl = (url) => /^(www|https?)/gm.test(url)

const Markdown = ({ children }) => {
  return (
    <MDXProvider
      components={{
        h1: (props) => <h2 className="h2" {...props} />,
        h2: (props) => <h2 className="h3" {...props} />,
        h3: (props) => <h3 className="h4" {...props} />,
        h4: (props) => <h4 className="h5" {...props} />,
        h5: (props) => <h5 className="h6" {...props} />,
        h6: (props) => <h6 className="h6" {...props} />,
        p: (props) => <p {...props} />,
        a: (props) => (
          <a
            className="link"
            {...(isExternalUrl(props.href) && {
              rel: 'external noreferrer',
              target: '_blank',
            })}
            {...props}
          />
        ),
      }}
    >
      {children}
    </MDXProvider>
  )
}

export default Markdown
