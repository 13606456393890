import React from 'react'

import Markdown from 'components/Markdown'

const Component = ({ title, children, lastUpdatedAt }) => (
  <div className="py-4 sm:py-8 md:py-16 lg:py-24">
    <div className="container">
      <div className="space-y-8">
        <div className="container">
          <h1 className="h1">{title}</h1>
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <div className="space-y-4">
              <Markdown>{children}</Markdown>

              {lastUpdatedAt && (
                <p className="h6">Last updated &mdash; {lastUpdatedAt}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Component
